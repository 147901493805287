<template>
  <edit-dialog
    ref="editDialog"
    :data="geoframe"
    :el-rules="elementRules"
    :override-title="title"
    :visible="visible"
    :save-state="savingGeoframe"
    width="600px"
    @request-close="$emit('update:visible', false)"
    @save="data => { $emit('save', data); savingGeoframe = true; }"
  >
    <template slot-scope="{ formData, error }">
      <div>
        <div v-show="error">
          <el-alert type="error">
            {{ error }}
          </el-alert>
        </div>
        <div class="form">
          <el-form-item
            label="Name"
            prop="name"
          >
            <el-input
              v-model="formData.name"
              placeholder="Geoframe Name"
            />
            <div>
              <dedupe-control v-model="formData.dedupe" />
            </div>
          </el-form-item>
          <el-form-item label="Tags">
            <tag-picker :tags.sync="formData.tags" />
          </el-form-item>
          <el-form-item
            label="Organization"
            prop="organization"
          >
            <org-picker :organization.sync="formData.organization" />
          </el-form-item>
        </div>
      </div>
    </template>
  </edit-dialog>
</template>

<script>
import validator from 'validator';
import DedupeControl from '@/components/global/DedupeControl.vue';
import OrgPicker from '@/components/global/OrgPicker.vue';
import TagPicker from '@/components/global/TagPicker.vue';
import EditDialog from '@/components/global/EditDialog.vue';

const whitelistedChars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,-\' ';

export default {
  name: 'GeoframeFormDialog',
  components: { DedupeControl, EditDialog, OrgPicker, TagPicker },
  props: {
    geoframe: { type: Object, default: () => ({}) },
    visible: { type: Boolean, required: true },
  },

  data() {
    return {
      elementRules: {
        name: [
          {
            required: true,
            message: 'Geoframe Name is required.',
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (value.trim().length < 1) {
                callback(new Error('Geoframe Name is required.'));
              }
              callback();
            },
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (!validator.isWhitelisted(value, whitelistedChars)) {
                callback(new Error('Names must only contain alpha-numeric characters, commas, hyphens, spaces and apostrophes.'));
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
        organization: [
          {
            validator(rule, value, callback) {
              if (!value) {
                callback(new Error('Please choose an organization.'));
              } else {
                callback();
              }
            },
          },
        ],
      },
      formData: {},
      savingGeoframe: false,
    };
  },

  computed: {
    title() {
      if (this.geoframe.id) {
        return 'Edit Geoframe';
      }

      return 'Create Geoframe';
    },
  },

  watch: {
    visible(value) {
      this.formData = Object.freeze(this.geoframe);
      this.savingGeoframe = false;
      this.$refs.editDialog.reset();
    },
  },
};
</script>
