<template>
  <div class="geo-search">
    <div class="search-container">
      <el-autocomplete
        v-model="query"
        class="searchbox"
        :fetch-suggestions="queryProvider"
        :trigger-on-focus="false"
        clearable
        prefix-icon="fa fa-search"
        placeholder="Search by business name or address"
        spellcheck="false"
        @input="showReportAddrBtn = false"
        @select="chooseFeature"
      />
      <el-button
        v-show="showReportAddrBtn"
        plain
        type="danger"
        @click="reportBadAddr"
      >
        Report Address
      </el-button>
    </div>
    <div class="buttons">
      <el-dropdown
        split-button
        type="primary"
        @click="$emit('plot', { points: poiPoints, clear: true })"
        @command="$emit('plot', { points: poiPoints, clear: false })"
      >
        Plot Points
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="addPoints">
            Add Plot Points
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <help-tip
        class="help"
        style="margin-left: 0.5em"
      >
        <p>
          To draw polygons, click to define points around your location.
        </p>
        <p>
          Click back on the first point to close the shape. You can also adjust the shape of a current Geoframe by double clicking on an existing anchor point.
        </p>
        <p>
          Press <b style="color:blue;">ESC</b> to exit edit mode.
        </p>
        <p>
          <center>*Note that the maximum area limit for Geoframes is 2,880,648 m² or 1.11 sq mi.</center>
        </p>
      </help-tip>
    </div>

    <el-dialog
      title="Report Address"
      :visible.sync="showReportDialogCreate"
    >
      <div v-if="feature">
        <el-form label-width="120px">
          <el-form-item label="Latitude">
            <el-input
              readonly
              :value="feature.center[1]"
            />
          </el-form-item>
          <el-form-item label="Longitude">
            <el-input
              readonly
              :value="feature.center[0]"
            />
          </el-form-item>
          <el-form-item label="Entered Address">
            <el-input
              readonly
              :value="feature.value"
            />
          </el-form-item>
          <el-form-item label="Issue">
            <el-select
              v-model="badAddrReason"
              class="issue-dropdown"
            >
              <el-option
                label="Wrong location on map"
                value="wrong location"
              />
              <el-option
                label="Map quality/resolution too low"
                value="low resolution"
              />
              <el-option
                label="Cloud cover blocking POI(s)"
                value="cloud cover"
              />
              <el-option
                label="Other"
                value="other"
              />
            </el-select>
          </el-form-item>
          <div v-show="badAddrReason === 'wrong location'">
            <el-alert
              :closable="false"
              description="Paste a Google Maps link below of the correct location (should include latitude/longitude in the URL."
              type="info"
            />
            <el-form-item
              class="top-spacer"
              label-width="0px"
            >
              <el-input
                v-model="badAddrLink"
                placeholder="Google Maps link"
              />
              <div
                v-show="!validMapsUrl"
                class="el-form-item__error"
              >
                This doesn't appear to be a valid Google Maps link.
              </div>
            </el-form-item>
          </div>
          <el-form-item
            v-show="badAddrReason === 'other'"
            label="Explain"
          >
            <el-input
              v-model="badAddrOther"
              placeholder="Please describe the issue"
            />
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button
          :disabled="invalidReportState"
          type="primary"
          @click="sendReport"
        >
          Send Report
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import { order as orderApi, system as systemApi } from '@/adonis-api';
import HelpTip from './HelpTip.vue';

const apiUrl =
  'https://api.mapbox.com/geocoding/v5/mapbox.places/%s.json?access_token=' +
  process.env.VUE_APP_MAPBOX_TOKEN +
  '&autocomplete=true&country=us,ca&limit=10';
const gmapsRx = /^https?:\/\/.*google.*@-?\d{1,3}\.\d{1,},-?\d{1,3}\.\d{1,}/;

export default {
  name: 'GeoSearch',

  components: { HelpTip },

  data() {
    return {
      badAddrLink: '',
      badAddrOther: '',
      badAddrReason: '',
      feature: null,
      poiPoints: [],
      query: '',
      reason: '',
      showReportAddrBtn: false,
      showReportDialogCreate: false,
    };
  },

  computed: {
    ...mapState('geoframes', ['geoframeOrder']),

    invalidReportState() {
      return (
        !this.badAddrReason ||
        (this.badAddrReason === 'other' && !this.badAddrOther) ||
        (this.badAddrReason === 'wrong location' &&
          (!this.badAddrLink || !this.validMapsUrl))
      );
    },

    validMapsUrl() {
      return this.badAddrLink === '' || gmapsRx.test(this.badAddrLink);
    },
  },

  watch: {
    geoframeOrder(order) {
      if (order) {
        this.query = order.address;
        this.queryProvider(this.query, features => {
          this.chooseFeature(features[0]);
        });
        this.showReportAddrBtn = true;
      } else {
        this.showReportAddrBtn = false;
      }
    },

    query(val) {
      if (!val) {
        this.poiPoints = [];
        this.showReportAddrBtn = false;
      }
      const exploded = val.split(',');
      if (exploded.length === 2) {
        if (!isNaN(Number(exploded[0])) && !isNaN(Number(exploded[1]))) {
          this.feature = {
            address: 'Manually specified Lat/Lng',
            center: [
              Number(exploded[1]),
              Number(exploded[0]),
            ],
          };
          this.$emit('select', this.feature);
          this.showReportAddrBtn = false;
        }
      }
    },
  },

  methods: {
    chooseFeature(feature) {
      this.$emit('select', {
        address: feature.value,
        bbox: feature.bbox,
        center: feature.center,
      });
      this.feature = feature;
      this.showReportAddrBtn = true;
    },

    clear() {
      this.query = '';
      this.poiPoints = [];
    },

    async handleReportAddr() {
      await orderApi.reportOrderItem(this.geoframeOrder.orderItemId, {
        orderId: this.geoframeOrder.orderId,
        status: 'ERROR',
        reason: this.reason,
      });

      this.$store.dispatch('geoframes/clearGeoframeOrder');
      this.$store.dispatch('event', {
        eventType: 'ORDER_REPORTED',
      });
      this.$emit('report-address');
    },

    async queryProvider(query, callback) {
      if (!query) return;
      let result, finalUrl;

      try {
        finalUrl = apiUrl.replace(
          '%s',
          query.replace('#', '%23').replace(/[\\!@$%^&*()+=;:]/, ' '),
        );
        result = await axios.get(finalUrl);
      } catch (e) {
        this.$reportError(e, {
          finalUrl,
        });
      }

      if (result.status !== 200) {
        return;
      }

      const features = result.data.features;

      /**
       * Populate poiPoints with exact points (no bounding boxes) so the user
       * has the option of plotting these markers on the map.
       */
      this.poiPoints = features
        .filter(feature => !feature.bbox)
        .map(feature => ({
          address: feature.properties.address,
          center: feature.center,
          label: feature.text,
        }));

      callback(
        features.map(feature => ({
          bbox: feature.bbox,
          center: feature.center,
          value: feature.place_name,
        })),
      );
    },

    reportBadAddr() {
      this.badAddrLink = this.badAddrReason = this.badAddrOther = '';
      this.showReportDialogCreate = true;
    },

    async sendReport() {
      const params = {
        latLng: {
          lng: this.feature.center[0],
          lat: this.feature.center[1],
        },
        address: this.feature.value,
        issue: this.badAddrReason,
        ...(this.badAddrReason === 'wrong location'
          ? {
            mapLink: this.badAddrLink,
          }
          : {}),
        ...(this.badAddrReason === 'other'
          ? {
            other: this.badAddrOther,
          }
          : {}),
      };

      if (this.geoframeOrder) {
        this.reason = (this.badAddrReason === 'other') ? this.badAddrOther : this.badAddrReason;
        try {
          this.handleReportAddr();
        } catch (e) {
          this.$notify.error({
            message:
            "There was a problem reporting this address. We've been notified of the issue.",
          });
          this.$reportError(e);
        }
      }

      try {
        await systemApi.reportAddress(params);
      } catch (e) {
        this.$notify.error({
          message:
            "There was a problem reporting this address. We've been notified of the issue.",
        });
        this.$reportError(e);
      } finally {
        this.showReportDialogCreate = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

.geo-search {
  display: flex;
  align-items: flex-start;
}

.search-container {
  display: flex;
  flex-direction: column;
}

.searchbox {
  width: 400px;
}

.buttons {
  align-items: center;
  display: flex;
  margin-left: $--main-padding / 2;

  > div:not(:last-of-type) {
    margin-right: 8px;
  }
}

.reason-input {
  margin-top: 1em;
}

.issue-dropdown {
  width: 100%;
}

.top-spacer {
  margin-top: 1rem;
}
</style>
