<template>
  <div>
    <h3>Create Geoframe</h3>
    <div class="top-bar">
      <div class="search">
        <geo-search
          ref="search"
          @plot="plotPois"
          @select="selectPlace"
        />
      </div>
      <div class="form-controls">
        <el-button
          v-show="showReset"
          type="secondary"
          class="reset"
          @click="resetForm"
        >
          Reset
        </el-button>
        <date-picker
          class="date-picker"
          :date-range.sync="dateRange"
          :organization="organization"
          @blur="dateBlur"
        />
        <el-button
          :disabled="!validSelection || historicalSelection || deviceCount > 0"
          :loading="countPending"
          type="info"
          @click="getCount"
        >
          <span v-show="deviceCount">
            <span
              ref="deviceCountContainer"
              class="device-count"
            >
              0
            </span>
            Devices
          </span>
          <span v-show="!deviceCount">
            Get Count
          </span>
        </el-button>
        <el-button
          v-if="isOrder"
          :disabled="!canCreateGeoframe"
          type="primary"
          @click="handleSave(geoframe)"
        >
          Save Geoframe
        </el-button>
        <el-button
          v-else
          :disabled="!canCreateGeoframe"
          type="primary"
          @click="showEditDialog = true"
        >
          Create Geoframe
        </el-button>
      </div>
    </div>
    <poly-map
      ref="map"
      editable
      :is-order="isOrder"
      @error="handleMapError"
      @update="mapUpdate"
    />
    <geoframe-form-dialog
      :geoframe="geoframe"
      :visible.sync="showEditDialog"
      @save="handleSave"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GeoframeFormDialog from '@/components/geoframe/GeoframeFormDialog';
import PolyMap from '@/components/global/PolyMap';
import DatePicker from '@/components/global/DatePicker.vue';
import GeoSearch from '@/components/global/GeoSearch.vue';
import moment from 'moment';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import { geoframe } from '@/adonis-api';
import { CountUp } from 'countup.js';
import { dateToString } from '@/helpers';
import { getValidationErrors } from '@/helpers/validation-rules';
import { datePickerWindow } from '@/constants';

const SLOW_COUNT_DELAY = 15000; // ms

export default {
  name: 'PageGeoframeCreate',
  components: { GeoframeFormDialog, DatePicker, GeoSearch, PolyMap },

  props: {
    isOrder: { type: Boolean, default: false },
  },

  data() {
    return {
      countPending: false,
      deviceCount: 0,
      historicalAccess: false,
      countJobId: null,
      dateRange: [],
      organization: {},
      savingGeoframe: false,
      mapErrorState: false,
      geoframe: {},
      geojson: {
        geometry: {
          coordinates: [],
        },
      },
      totalArea: 0,
      showEditDialog: false,
    };
  },

  computed: {
    ...mapState('geoframes', ['geoframeOrder']),
    ...mapGetters('settings', ['rangeEnd', 'rangeStart', 'maximumArea']),
    ...mapGetters('user', ['hasPermission']),
    ...mapState(['event']),

    countDelay() {
      if (this.deviceCount > 0 && this.deviceCount < 1000) {
        return 500;
      }
      return 1000;
    },

    polygonCount() {
      return this.geojson.geometry.coordinates.length;
    },

    canCreateGeoframe() {
      return (
        this.validSelection &&
        (!this.historicalSelection || (this.historicalSelection && this.historicalAccess))
      );
    },

    historicalSelection() {
      return moment(this.dateRange[0]).isBefore(
        this.rangeStart.clone().startOf('day'),
      );
    },

    jobInfo() {
      return this.$store.getters['mqtt/getJob'](this.countJobId);
    },

    showReset() {
      if (this.polygonCount > 0) return true;

      return false;
    },

    validSelection() {
      return (
        !this.mapErrorState &&
        this.polygonCount > 0 &&
        !this.selfIntersect &&
        (this.totalArea > 0 && this.totalArea <= this.maximumArea)
      );
    },
  },

  watch: {
    geojson(newValue, oldValue) {
      if (
        newValue.length !== oldValue.length ||
        !_isEqual(newValue, oldValue)
      ) {
        new CountUp(
          this.$refs.deviceCountContainer,
          0,
          {
            startVal: this.deviceCount,
            duration: (this.countDelay / 1000),
          },
        ).start();
        setTimeout(() => {
          this.deviceCount = 0;
        }, this.countDelay);
      }
    },

    geoframeOrder(order) {
      if (order) {
        const duration = moment
          .duration(moment(order.endDate).diff(moment(order.startDate)))
          .asMonths();
        let endDate;

        if (duration > datePickerWindow.normal && !this.hasPermission('unrestricted_date_window')) {
          this.$notify.warning({
            title: 'Invalid Date Range',
            message:
              'The date range of the selected geoframe is invalid. ' +
              "It's been modified to a shorter range.",
          });

          endDate = moment(order.startDate).add(datePickerWindow.normal, 'months');
        } else {
          endDate = moment(order.endDate);
        }

        this.geoframe = {
          name: order.name,
          tags: order.tags,
          organization: order.organization,
          dedupe: true,
        };

        this.organization = order.organization;
        this.dateRange = [moment(order.startDate), endDate];
      }
    },

    jobInfo: {
      handler(jobInfo) {
        if (
          _get(jobInfo, 'payload.result') &&
          _get(jobInfo, 'payload.status') === 'COMPLETE' &&
          !this.savingGeoframe
        ) {
          this.countPending = false;
          const previousCount = this.deviceCount;
          this.deviceCount = jobInfo.payload.result.count;

          const numberAnimation = new CountUp(
            this.$refs.deviceCountContainer,
            jobInfo.payload.result.count,
            {
              startVal: previousCount,
              duration: 1,
            },
          );
          numberAnimation.start();

          clearTimeout(this.slowTimer);
        }
      },
      deep: true,
    },
  },

  async created() {
    await this.$nextTick();
    this.resetForm();
    this.stopSub = this.$store.subscribeAction(action => {
      if (
        action.type === 'event' &&
        (action.payload.eventType === 'ORDER_PROCESSED' ||
          action.payload.eventType === 'ORDER_REPORTED')
      ) {
        this.resetForm();
      }
    });
  },

  beforeDestroy() {
    this.stopSub && this.stopSub();
  },

  methods: {
    dateBlur() {
      if (this.deviceCount !== 0) {
        new CountUp(
          this.$refs.deviceCountContainer,
          0,
          {
            startVal: this.deviceCount,
            duration: (this.countDelay / 1000),
          },
        ).start();
        setTimeout(() => {
          this.deviceCount = 0;
        }, this.countDelay);
      }
    },

    handleMapError(errorState) {
      this.mapErrorState = errorState;
    },

    async handleSave(data) {
      this.savingGeoframe = true;
      clearTimeout(this.slowTimer);
      const params = {
        geojson: this.geojson,
        name: data.name.trim(),
        organization_id: data.organization.id,
        start_date: dateToString(this.dateRange[0]),
        end_date: dateToString(this.dateRange[1]),
        count_pending: this.countPending,
        historical: this.historicalSelection,
        count: this.deviceCount,
        job_id: this.countJobId,
        tags: data.tags,
        auto_dedupe: data.dedupe,
      };

      try {
        const response = await geoframe.create(params);

        if (response.data.job) {
          this.$store.dispatch('mqtt/addRawJob', response.data.job);
        }

        if (this.geoframeOrder) {
          this.$store.dispatch('event', {
            eventType: 'ORDER_PROCESSED',
            itemId: this.geoframeOrder.orderItemId,
            orderId: this.geoframeOrder.orderId,
          });
        }
        this.$message({
          message: 'Geoframe saved.',
          type: 'success',
        });
        window.mixpanel.track('Create Geoframe', {
          Name: data.name.trim(),
          Organization: data.organization.name,
          'Start Date': dateToString(this.dateRange[0]),
          'End Date': dateToString(this.dateRange[1]),
          Historical: this.historicalSelection,
          'Device Count': this.deviceCount,
        });
        this.resetForm();
      } catch (e) {
        if (e.response) {
          const errors = getValidationErrors(e);

          if (errors.name) {
            this.$notify.error({
              message: errors.name,
            });
            return;
          }
        }

        this.$notify.error({
          message:
            'There was a problem saving this geoframe. This issue has been reported.',
        });
        this.$reportError(e, {
          componentName: this.$options.name,
          newGeoframe: {
            ...params,
            geojson: '[omitted]',
          },
        });
      } finally {
        this.savingGeoframe = false;
        this.showEditDialog = false;
      }
    },

    mapUpdate({ geojson, totalArea }) {
      this.geojson = geojson;
      this.totalArea = totalArea;
    },

    plotPois({ points, clear }) {
      window.mixpanel.track('Plot Points');
      this.$refs.map.plotPois(points, clear);
    },

    selectPlace(feature) {
      this.$refs.map.pinpoint(feature);
    },

    resetForm() {
      // Prevent slow-count message after save...
      clearTimeout(this.slowTimer);

      // Name must be a string, NOT null!
      this.geoframe = {
        name: '',
        organization: Object.freeze(this.$store.state.user.orgDetails),
        dedupe: this.$store.getters['user/autoDedupe'],
        tags: [],
      };

      this.countJobId = null;
      this.countPending = false;

      this.dateRange = [
        moment()
          .subtract(2, 'months')
          .startOf('month')
          .toDate(),
        moment()
          .utc()
          .subtract(this.rangeEnd, 'days')
          .toDate(),
      ];

      this.organization = this.$store.state.user.orgDetails;
      this.$refs.search.clear();
      this.$refs.map.reset();
      document.body && this.$scrollTo(document.body);
      this.$store.dispatch('geoframes/clearGeoframeOrder');
    },

    async getCount() {
      try {
        this.countPending = true;
        const job = await geoframe.getCount({
          name: '',
          geojson: this.geojson,
          organizationId: this.organization.id,
          startDate: dateToString(this.dateRange[0]),
          endDate: dateToString(this.dateRange[1]),
        });

        this.$store.dispatch('mqtt/addRawJob', job);
        this.countJobId = job.id;
        this.slowTimer = setTimeout(() => {
          this.$notify.info({
            duration: 8000,
            title: 'Count Still Processing',
            message:
              'This seems to be taking a long time. You can either continue waiting, or save and we will keep working on it in the background while you do other things.',
          });
        }, SLOW_COUNT_DELAY);
      } catch (e) {
        this.countPending = false;
        this.$notify.error({
          message: 'Geoframe count failed. This issue has been reported.',
          title: 'Count Failed',
        });
        this.$reportError(e, {
          componentName: this.$options.name,
          prospectiveCount: true,
          geoframeName: this.name.trim(),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

h3 {
  font-weight: 300;
  font-size: 26px;
  margin: 0 0 5px !important;
}

.my-map {
  position: relative;
}

.search {
  margin: $--main-padding 0;
  width: 560px;
  :deep(.searchbox) {
    width: 320px;
  }
}

.reset {
  margin-right: .8em;
}

.top-bar {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.form-controls {
  justify-content: space-between;
  display: flex;
  margin: 20px 0;
}

.date-picker {
  width: 250px;
}

:deep(.el-date-editor) {
  margin-right: .75em;
}
</style>
